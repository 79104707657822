// ===============================================================
// ---------------------------------------------------------------
// Dependencies
import { useEffect, useContext, Fragment, Suspense, lazy } from "react"

// Context
import AppContext from "../../Context/App/appContext"
import AppNavbar from "../Modules/AppNavbar"

// Modules
import Spinner from "../Modules/Spinner"
import Theme from "../Modules/Theme"

// Lazy Modules
const HowToHeader = lazy(() => import("../Modules/HowToHeader"))

const Footer = lazy(() => import("../Modules/Footer"))

// ===============================================================
const HowToUse = () => {
	// ---------------------------------------------------------------
	// Context
	const appContext = useContext(AppContext)
	const { getPeople, people } = appContext

	// ---------------------------------------------------------------
	// Get content and scroll to top on load
	useEffect(() => {
		getPeople()

		let currentLocation = window.location.href;
		console.log(currentLocation)
		const hasCommentAnchor = currentLocation.includes("#pay-bills");


		if (hasCommentAnchor) {
		}

		else {
			window.scrollTo(0, 0)
		}
		// eslint-disable-next-line
	}, [])

	// function scrollToDiv2(id) {


	// 	const anchorComment = document.getElementById("pay-bills");
	// 	console.log('div found = ' + anchorComment)
	// 	if (anchorComment) {
	// 		let y = anchorComment.scrollHeight
	// 		console.log(anchorComment.scrollHeight)
	// 		window.scroll({ top: y, behavior: 'smooth' });


	// 	}
	// }


	return (
		<Fragment>
			<Suspense fallback={<Spinner />}>
				{people ? (
					<Theme id="how-to-use" className="how-to-use">
						<AppNavbar />
						<HowToHeader />
						<Footer />
					</Theme>
				) : (
					<Spinner />
				)}
			</Suspense>
		</Fragment>
	)
}

export default HowToUse
